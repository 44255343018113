import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
import "../styles/events.scss";
import CustomMarkdown from "../utils/customMarkdown";
import { GatsbyImage } from "gatsby-plugin-image"
import GetImgUrl from "../utils/customFunctions"

import { Helmet } from "react-helmet"

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"
import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"
import HomeCards from "../components/home-cards/home-cards";
import Comparison from "../components/comparison/comparison";
import ImageCarousel from "../components/image-carousel/image-carousel";
import HtmlEditor from "../components/html-editor/html-editor";

// const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
// const Header = loadable(() => import("../components/header/header"))
// const Banner = loadable(() => import("../components/banner/banner"))
// const Reviews = loadable(() => import("../components/reviews/reviews"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
// const Accordion = loadable(() => import("../components/accordion/accordion"))
// const CTAList = loadable(() => import("../components/cta-list/ctaList"))
// const CTABanner = loadable(() => import("../components/cta-banner/cta-banner"))
// const Providers = loadable(() => import("../components/provider-list/providers"))
// const Comparison = loadable(() => import("../components/comparison/comparison"))
// const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))
// const HomeCards = loadable(() => import("../components/home-cards/home-cards"))


export const query = graphql`
  query eventPagesQuery($id: Int) {
    strapiEvents(strapiId: { eq: $id }) {
          PageComponent
          FromDate
          Title
          SEOTitle
          SEODescription
          FooterDisclaimer
          Description
          DisplayImageOnPage
          ToDate
          Image {
            localFile{
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1900) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    gatsbyImageData(
                      width: 1900
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
            }
        }
          
      }
    }
`;

const Event = ({ data }) => {

  var options = { year: 'numeric', month: 'long', day: 'numeric', hour12: true };
  const today = new Date();

  function formatDate(date) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return monthNames[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + "  " + strTime;
  }

  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {

        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null


      }
      return ("")

    })

    return (imgUrl)
  }

  return (
    <>
      <Layout SEOTitle={data.strapiEvents.SEOTitle || data.strapiEvents.Title} SEODescription={data.strapiEvents.SEODescription} footerDisclaimer={data.strapiEvents.FooterDisclaimer}>

        {/** List of meta properties coming from strapi that applies only for the primary pages */
          <Helmet>
            {data.strapiEvents.MetaTags?.map((item, index) => {
              return (
                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
              )
            })}
          </Helmet>
        }

        <div className="events-page">
          <div className="events-container">
            <div className="events-content padding">
              <div className="event">
                {data.strapiEvents.ToDate ? new Date(data.strapiEvents.ToDate) < today ?

                  <div className="event-passed-container">
                    <div className="event-passed-content">
                      <p>This event has passed.</p>
                    </div>
                  </div> : "" : ""
                }
                {data.strapiEvents.Title ? <h3 className="event-title">{data.strapiEvents.Title}</h3> : ""}
                {data.strapiEvents.FromDate && data.strapiEvents.ToDate ?

                  data.strapiEvents.FromDate == data.strapiEvents.ToDate ?

                    <p className="event-date">{formatDate(new Date(data.strapiEvents.FromDate))}</p> :

                    <p className="event-date">{formatDate(new Date(data.strapiEvents.FromDate)) + ' - ' + formatDate(new Date(data.strapiEvents.ToDate))}</p>

                  : data.strapiEvents.FromDate ? <p className="event-date">{formatDate(new Date(data.strapiEvents.FromDate))}</p> : ""
                }
                {
                  data.strapiEvents.Description ?
                    <CustomMarkdown children={data.strapiEvents.Description} className="event-description" /> : ""
                }

                {
                  data.strapiEvents.DisplayImageOnPage === 'yes' ? <GatsbyImage className="cta-card-image" image={data.strapiEvents.Image.gatsbyImageData} alt=""></GatsbyImage> : ""
                }
                {
                  data.strapiEvents.DisplayImageOnPage === 'yes' ? <GatsbyImage className="cta-card-image" image={data.strapiEvents.Image.localFile.childImageSharp.gatsbyImageData} alt="" ></GatsbyImage> : ""
                }
              </div>


            </div>

          </div>
          {
            data.strapiEvents.PageComponent?.map((item, index) => {


              switch (item.strapi_component) {
                case "page-components.header":

                  return (
                    <>
                      <Header key={index}
                        title={item.Title}
                        markdownTitle={item.MarkdownTitle}
                        subtitle={item.Subtitle}
                        secondarySubtitle={item.SecondSubtitle}
                        CTAText={item.CTALabel}
                        CTAUrl={item.CTAUrl}
                        CTA_nofollow={item.CTA_nofollow}
                        Disclaimer={item.Disclaimer}
                        Background={item.Background !== null ? GetImgUrl(item.Background.localFile___NODE).gatsbyImageData : ""}
                        HeaderHeight={item.HeaderHeight}
                      ></Header>
                    </>

                  )

                case "page-components.picture":
                  
                  return (
                    (item.Image)?
                  <>            
                    <div className="events-container" style={{ padding: '0px 32px 32px 32px' }}>
                            
                     <GatsbyImage className="cta-card-image" image={GetImgUrl(item.Image.localFile___NODE).gatsbyImageData} alt="" ></GatsbyImage> 
                      
                    </div>
                  </>
                  :""
                  )


                case "page-components.image-slider":
                  var contentsList = []

                  item.Picture.map((image, index) => {

                    var imageUrl = GetImgUrl(image.Image.localFile___NODE).gatsbyImageData
                    contentsList.push({
                      ALT: image.ALT,
                      Image: imageUrl,
                    })
                    return ("")
                  })
                  return (

                    <ImageCarousel
                      key={index}
                      contents={contentsList}
                    ></ImageCarousel>
                  )
                case "page-components.comparison":
                  return (

                    <Comparison key={index}
                      title={item.Title}
                      step1={item.Step1}
                      step2={item.Step2}
                      step3={item.Step3}
                      image1={getImgUrl(item.Image1.localFile___NODE)}
                      image2={getImgUrl(item.Image2.localFile___NODE)}
                      image3={getImgUrl(item.Image3.localFile___NODE)}
                    ></Comparison>


                  )


                case "page-components.cta-banner":
                  return (

                    <CTABanner
                      key={index}
                      Title={item.Title}
                      Subtitle={item.Subtitle}
                      ctaUrl={item.CTAUrl}
                      ctaLabel={item.CTALabel}
                    ></CTABanner>

                  )

                case "page-components.banner":
                  var imageUrl = GetImgUrl(item.Image.localFile___NODE).gatsbyImageData
                  return (
                    <Banner
                      key={index}
                      text={item.Text}
                      image={imageUrl}
                      imageAlt={item.Image.alternativeText}
                      dark={item.DarkBackground}
                      title={item.Title}
                      subtitle={item.Subtitle}
                    ></Banner>

                  )

                case "page-components.home-cards":
                  var contentsList = []
                  item.CTACard.map((CTAitem, index) => {
                    var imageUrl = CTAitem.Image !== null ? getImgUrl(CTAitem.Image.localFile___NODE) : ""
                    contentsList.push({
                      Title: CTAitem.Title,
                      URL: CTAitem.URL,
                      Image: imageUrl,
                      Description: CTAitem.Description,
                    })
                    return ("")
                  })
                  return (

                    <HomeCards
                      key={index}
                      contents={contentsList}
                    ></HomeCards>


                  )

                case "page-components.paragraph":
                  return (

                    <Paragraph
                      key={index}
                      title={item.Title ? item.Title : ""}
                      subtitle={item.SubTitle ? item.SubTitle : ""}
                      text={item.Text}
                      bgColor={item.BackgroundColor || ""}

                    ></Paragraph>
                  )

                case "page-components.cta-list":
                  var contentsList = []
                  item.CTAList.map((CTAitem, index) => {
                    var imageUrl = GetImgUrl(CTAitem.Image.localFile___NODE).gatsbyImageData
                    contentsList.push({
                      Title: CTAitem.Title,
                      URL: CTAitem.URL,
                      URLLabel: CTAitem.URLLabel,
                      ImageURL: CTAitem.Image,
                      ImageURL: imageUrl,
                      Alt: CTAitem.Image.alternativeText,
                      Description: CTAitem.Description,
                      DescriptionCharLimit: CTAitem.DescriptionCharLimit
                    })
                    return ("")
                  })
                  return (

                    <CTAList
                      key={index}
                      title={item.Title ? item.Title : ""}
                      subtitle={item.Subtitle}
                      contents={contentsList}
                      cardStyle={item.CardStyle}
                    ></CTAList>


                  )

                case "page-components.html-editor":
                  return (

                    <HtmlEditor
                      key={index}
                      htmlContent={item.HTML}
                      fullWidth={item.FullWidth}
                    ></HtmlEditor>


                  )

                default:
                  return ("")

              }
            })
          }
        </div>

      </Layout>

    </>

  )

}

export default Event