import { Link } from "gatsby"
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"


import './home-cards.scss';

const HomeCards = ({ contents }) => {

    return (

        <div className="home-cards-container">
            <div className="home-cards-content">
                <div className="uk-child-width-1-4@xl uk-child-width-1-4@m uk-child-width-1-2@s uk-child-width-1-2 uk-grid home-card-grid" uk-grid="true">
                    {

                        contents.map((item, index) => {
                            return (
                                index % 2 == 0 ?
                                    <div key={index} className={`home-card ${index > 0 && index!==2 ? 'border-left' : 'border-left-white'}`}>
                                        <div className="uk-card uk-card-default">
                                            <div className="uk-card-body home-card-body">
                                                <Link to={item.URL} className="uk-card-title home-card-title">{item.Title}</Link>
                                                <p>{item.Description}</p>
                                            </div>
                                            <div className="uk-card-media-bottom">
                                                <GatsbyImage className={`home-card-image ${index == 0 ? 'first-image' : ''}`} image={item.Image.gatsbyImageData} alt="Card Image"></GatsbyImage>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div key={index} className={`home-card ${index > 0 ? 'border-left' : 'border-left-white'}`}>
                                        <div className="uk-card uk-card-default">
                                            <div className="uk-card-media-top">
                                                <GatsbyImage className="home-card-image" image={item.Image.gatsbyImageData} alt={"Card Image"}></GatsbyImage>
                                            </div>
                                            <div className="uk-card-body home-card-body">
                                                <Link to={item.URL} className="uk-card-title home-card-title">{item.Title}</Link>
                                                <p>{item.Description}</p>
                                            </div>
                                        </div>
                                    </div>

                            )


                        })
                    }
                </div>
            </div>
        </div>


    )


}

export default HomeCards