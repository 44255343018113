import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
//import 'uikit/dist/css/uikit.css'
import "./image-carousel.scss"
import { FaSearchPlus } from 'react-icons/fa'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

const ImageCarousel = ({ contents }) => {

    const [openModal, setOpenModal] = useState(false)
    const [imageURL, setImageURL] = useState("");


    const clickImage = (event) => {

        setOpenModal(!openModal)
        setImageURL(event);

        if (!openModal) {

            document.addEventListener("click", handleOutsideClick, false);

        } else {

            document.removeEventListener("click", handleOutsideClick, false);
        }


    }


    const handleOutsideClick = (e) => {

        // if (!this.node.contains(e.target)) this.handleClick();

    };

    return (
        <>
            <div className="uk-position-relative data-slider uk-visible-toggle uk-light" tabIndex="-1" data-uk-slider>

                <ul className="uk-slider-items uk-child-width-1-3@m uk-grid uk-child-width-1-2@s" uk-grid="true">
                    {
                        contents ? contents.map((item, index) => {
                            return (
                                <li className="image-item" key={index} onClick={(e) => clickImage(item.Image)}>
                                    {/* <GatsbyImage image={item.Image.localFile.childImageSharp.gatsbyImageData} alt="Image slider"></GatsbyImage> */}
                                    <GatsbyImage image={item.Image} alt={item.ALT} className="hover-img"></GatsbyImage>
                                    <div className="uk-position-center uk-panel overlay"><span className="search-plus-icon"></span></div>
                                    <div className="uk-position-center uk-panel overlay-icon"><span className="search-plus-icon"><FaSearchPlus /></span></div>
                                </li>
                            )
                        }) : ""
                    }
                </ul>

                <a className="uk-position-center-left uk-position-small uk-hidden-hover uk-icon uk-slidenav-previous uk-slidenav" href="#" uk-slidenav-previous="true" uk-slider-item="previous"><AiOutlineLeft /></a>
                <a className="uk-position-center-right uk-position-small uk-hidden-hover uk-icon uk-slidenav-next uk-slidenav" href="#" uk-slidenav-next="true" uk-slider-item="next"><AiOutlineRight /></a>

            </div> 
            
            <div className={`${openModal ? "show-modal " : "show-modal modal-hidden"}`}>
                <div className="modal-content">
                    <span className="close" onClick={() => setOpenModal(false)}>&times;</span>
                    <div className='uk-position-center '>
                        <div className="image-parent" tabIndex="0">
                        <GatsbyImage image={imageURL} alt={""} className="img-max-width"></GatsbyImage>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImageCarousel